* {
    margin:0;
    padding:0;
    font-family: 'Open Sans', sans-serif;
    transition: all 300ms;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -o-transition: all 300ms;
    font-size:14px;
}

.primary-color {color:#2B4591;}
.primary-color-hover:hover {color:#2B4591;}
.primary-color-background {background-color:#2B4591;}
.primary-color-outline {outline:1px solid #2B4591;}
.primary-color-background-hover:hover {background-color:#2B4591;}
.primary-color-outline-hover:hover {outline-color:#2B4591;}
.primary-color-light {color:#4D79FF;}
.primary-color-background-light {background-color:#4D79FF;}
.primary-color-border-light {border-color:#4D79FF;}
.primary-color-outline-light {outline:1px solid #4D79FF;}
.primary-color-background-light-hover:hover {background-color:#4D79FF;}

.primary-color-very-light {color:#D2DDFF;}
.primary-color-background-very-light {background-color:#D2DDFF;}


.secondary-color {color:#FF4D4D;}
.secondary-color-background {background-color:#FF4D4D;}
.secondary-color-outline {outline-color:#FF4D4D;}
.secondary-color-hover:hover {color:#912B2B;}
.secondary-color-outline-hover:hover {outline-color:#912B2B;}
.secondary-color-background-hover:hover {background-color:#912B2B;}
.box-shadow-dark {box-shadow:0 0 20px 5px rgba(0,0,0,.1);}
.box-shadow-light {box-shadow:0 0 20px 5px rgba(255,255,255,.1);}
.box-shadow-dark-hover:hover {box-shadow:0 0 20px 5px rgba(0,0,0,.1);}
.box-shadow-light-hover:hover {box-shadow:0 0 20px 5px rgba(255,255,255,.1);}

.white {color:#FFFFFF;}
.white-background {background:#FFFFFF;}
.black {color:#525367;}
.black-background {background:#525367;}

p, li, input, select, option, label, table {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color:#525367;
}

.clickable {
    cursor:pointer;
}

.clickable:hover {
    opacity:0.5;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    text-underline: none;
    color:#525367;
}

.modals {
    z-index:2000 !important;
}

.icon {
    font-size:14px;
    cursor:pointer;
    color:#4D79FF;
}

.field {
    width:240px;
    text-align:left;
}

.tiny {
    width:64px;
    text-align:left;
}

.icon:hover {
    opacity:0.8;
}

.delete-icon {
    color:#FF4D4D;
}

hr {
    height:1px;
    border:none;
    background-color: gray;
}

.fa-trash-alt {
    color:#FF4D4D;
}

html, body {
    width:100%;
    height:100%;
    font-weight: 300;
    text-align: center;
}

.active-glow-object:hover span {
    box-shadow: 0 0 192px 192px rgba(77,121,255,0.8);
    filter: saturate(1);
}

.active-glow-object span {
    width:1px;
    height:1px;
    position: absolute;
    right: 0;
    bottom: 0;
    box-shadow: 0 0 128px 128px rgba(77,121,255,0.5);
    filter: saturate(0.8);
}

.deactivated-glow-object:hover span {
    box-shadow: 0 0 192px 192px rgba(255,77,77,0.8);
    filter: saturate(1);
}

.deactivated-glow-object span {
    width:1px;
    height:1px;
    position: absolute;
    right: 0;
    bottom: 0;
    box-shadow: 0 0 128px 128px rgba(255,77,77,0.5);
    filter: saturate(0.8);
}

.react-tooltip {
    color:#E8E8F0 !important;
    background:#4D79FF !important;
}

/*SCROLLBAR*/
::-webkit-scrollbar {width: 6px;}
::-webkit-scrollbar-track {background:transparent;}
::-webkit-scrollbar-thumb {background: #4D79FF;border-radius:4px;}
::-webkit-scrollbar-thumb:hover {background: #2B4591;opacity:0.8;}
/*SELECTOR*/
::selection {color:#FFFFFF;background:#525367;}

i:hover {
    opacity: 0.8;
    cursor:pointer;
}

h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: normal;
    color:#525367;
}

h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: normal;
    color:#525367;
}

h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: normal;
    color:#525367;
}

.MuiAutocomplete-popper {
    transition: all 0ms;
    -webkit-transition: all 0ms;
    -moz-transition: all 0ms;
    -o-transition: all 0ms;
}

.react-tooltip {
    z-index:1000;
}

.actions-panel {
    position:absolute;
    top:15%;
    left:1%;
    bottom:26%;
    right:26%;
}

.main-panel {
    border-radius:10px;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
}

.actions-panel .actions-menu {
    z-index:10;
    position:absolute;
    top:4px;
    left:5px;
    display:flex;
}

.actions-panel .actions-menu li {
    margin:8px;
}

.actions-panel .actions-menu .nav-item {
    display:block;
    border-radius:4px;
}

.actions-panel .actions-menu .nav-item:hover {
    background:#525367;
}

.actions-panel .actions-menu .nav-item p {
    color:#525367;
    padding:10px;
    font-weight:bold;
}

.actions-panel .actions-menu .nav-item:hover p {
    color:#E8E8F0;
}

.actions-panel .actions-menu .active {
    background:#525367;
}

.actions-panel .actions-menu .active p {
    color: #E8E8F0;
}

.circular-progress-div {
    width:100%;
    height:100%;
    justify-content:center;
    display:flex;
}

@keyframes blink {
    0% {background:white;}
    50% {background:rgba(77,121,255,0.5);}
    100% {background:white;}
}

.blink {
    animation: blink 1s ease-in-out;
}

@media screen and (max-width: 1023px) {
    * {
        font-size:12px;
    }

    p, li, input, select, option, label, table {
        font-size: 12px;
    }

    i {
        font-size: 16px;
    }

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 16px;
    }

    h3 {
        font-size: 14px;
    }
}
