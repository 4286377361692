.batch-item {
    width:98%;
    height:72px;
    margin:8px auto;
    text-align:left;
    overflow:hidden;
    position:relative;
}

.batch-item .information-holder {
    width:94%;
    display:inline-block;
}

.batch-item .information-holder .option-li {
    width:13%;
    height:60px;
    float:left;
    margin:4px 0 0 8px;
    overflow-y:auto;
}

.batch-item .multiple-holder-li {
    width:100%;
    margin-bottom:4px;
}

.batch-item .edit-icon {
    padding:8px 2px 0 0;
    margin:0;
    float:right;
    cursor: pointer;
}

.batch-item .delete-icon {
    color:lightcoral;
    padding:8px 2px 0 0;
    margin:0;
    float:right;
    cursor: pointer;
}

.batch-item .order-status {
    height:100%;
    width:4px;
    float:left;
    border-top-left-radius:4px;
    border-bottom-left-radius:4px;
}

.batch-item .locked-info {
    margin-right:8px;
    color:grey;
    font-size:10px;
    float:right;
}

.batch-item .item-note {
    position:absolute;
    margin:8px 0 0 70%;
    color:grey;
}

.batch-item .document-positions-div p {
    font-size: 10px;
}