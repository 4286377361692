.master-data-info-dialog {

}

.master-data-info-dialog .surrounder {
    width:600px;
}

.master-data-info-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.master-data-info-dialog .paragraph {
    text-align:justify;
}

.master-data-info-dialog h3 {
    margin:16px 0;
}

.master-data-info-dialog .sample-data-link {
    color:#4D79FF;
}