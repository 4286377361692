.transaction-item {
    width:98%;
    height:72px;
    margin:4px auto;
    position:relative;
    cursor:pointer;
    overflow:hidden;
}

.transaction-item .article-info {
    padding:6px 0 0 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width:80%;
    z-index:100;
}

.transaction-item .business-partner-info {
    padding:2px 0 0 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width:80%;
}

.transaction-item .date-time-info {
    position: absolute;
    font-size:10px;
    float:right;
    bottom:4px;
    right:4px;
}

.transaction-item .delete-icon {
    position:absolute;
    bottom:6px;
    left:2px;
}

.transaction-item .count-info {
    position:absolute;
    top:6px;
    right:6px;
    font-weight:bold;
}

.transaction-item span {
    z-index:0;
}