.batches {
    width:100%;
    height:100%;
    text-align:left;
}

.batches .search-input {
    float:left;
    margin:64px 0 0 16px;
}

.batches .header-bar {
    display:inline-block;
    position:relative;
    width:100%;
}

.batches .field {
    width:160px;
}

.batches .search-select {
    margin:64px 0 0 16px;
}

.batches .search-button {
    margin:64px 0 0 16px;
    float:left;
}

.batches .filter-select {
    margin:64px 0 0 16px;
}

.batches .download-icon {
    float:right;
    margin:32px 32px 0 0;
}

.batches .item-list {
    position: absolute;
    top:172px;
    bottom:0;
    right:0;
    left:0;
    overflow-y:auto;
}

.batches .upload-fab {
    position:absolute;
    bottom:32px;
    right:164px;
    background-color: #4D79FF;
}

.batches .add-fab {
    position:absolute;
    bottom:32px;
    right:80px;
}

.batches .pdf-fab {
    position:absolute;
    bottom:32px;
    right:164px;
    background-color: #4D79FF;
}

.batches .export-fab {
    position:absolute;
    bottom:32px;
    right:248px;
    background-color: #4D79FF;
}

.batches .info-icon {
    position:absolute;
    top:8px;
    right:8px;
}

.batches .filter-icon {
    margin:8px 0 0 16px;
    width:32px;
    height:32px;
}

.batches .filter-options {
    width:256px;
    display:inline-block;
    position:absolute;
    margin:16px 0 0 64px;
    padding:8px;
}

.batches .checkbox-field {
    padding:0;
    width:320px !important;
    margin:64px 0 0 8px;
}

.batches .loading-holder {
    position:relative;
    width:100%;
    height:100%;
    display:flex;
    text-align:center;
}

.batches .load-icon {
    margin:32px auto 0 auto;
}

.batches .warning-icon {
    width:32px;
    height:32px;
    margin:16px 16px 0 0;
    float:right;
}

.batches .urgent-item-count {
    float:right;
    font-size: 16px;
    margin:8px 8px 0 0;
}

.batches .default-icon {
    float:left;
}

.batches .sort-options {
    position:absolute;
    top:140px;
    left:8px;
    width:100%;
}

.batches .sort-options li {
    display:inline-block;
    margin-left:8px;
    width:12%;
}

.batches .sort-option {
    cursor:pointer;
    height:20px;
    padding:2px 4px 0 4px;
    text-align: center;
}

.batches .switch-holder{
    float:right;
    width:256px;
    max-width:19%;
    margin-top:16px;
}

.batches .switch-label {
    float:left;
    margin:0;
}

.batches .switch {
    float:left;
    margin:0;
}

.batches .info-extension {
    font-size:10px;
    color:grey;
    margin:0 8px;
    width:100%;
    float:left;
}

.batches .position-top-holder {
    width:100%;
    height:64px;
    display:inline-block;
    margin:8px 32px;
}

.batches .position-top-holder li {
    width:128px;
    text-align:left;
    margin-left:8px;
    display:inline-block;
}

@media screen and (max-width: 1500px) {
    .batches .field {
        width:128px;
    }
}