.article-movement {
    width:100%;
    height:100%;
    overflow:hidden;
    position:relative;
    border-radius:10px;
}

.article-movement .inventory-actions {
    position:absolute;
    float:left;
    margin:64px 0 0 32px;
}

.article-movement .locked-transactions-fcl {
    float:right;
    position:absolute;
    top:0;
    right:0;
}

.article-movement .fields {
    position:absolute;
    top:220px;
    left:0;
    margin:0;
    padding:0;
}

.article-movement .article-movement-content {
    position:absolute;
    top:64px;
    left:8px;
    right:0;
    bottom:0;
}

.article-movement .field {
    margin-top:16px;
}

.article-movement .count-input {
    margin:16px 0 0 16px;
    width:96px;
    float:left;
}

.article-movement .small-field {
    width:128px;
}

.article-movement .count-holder {
    float:left;
    margin:64px 0 0 32px;
}

.article-movement .book-switch {
    position:absolute;
    top:16px;
    left:32px;
}

.article-movement .book-button {
    position: absolute;
    right:352px;
    bottom:32px;
    z-index:500;
}

.article-movement .transactions-holder {
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    width:320px;
    z-index:100;
}

.article-movement .transaction-history {
    position:absolute;
    top:96px;
    right:4px;
    bottom:0;
    width:100%;
    overflow-y:auto;
}

.article-movement .sub-header {
    margin:16px 0;
}

.article-movement .actions-holder {
    position:absolute;
    top:0;
    right:0;
    width:100%;
    height:96px;
}

.article-movement .actions-holder .icon {
    width:24px;
    height:24px;
    margin:8px;
}

.article-movement .search-input {
    width:98%;
}

.article-movement .category-holder {
    display:flex;
}

.article-movement .article-movement-book-in-glow {
    width:1px;
    height:1px;
    position: absolute;
    left: 0;
    bottom: 0;
    box-shadow: 0 0 128px 128px rgba(77,121,255,0.5);
    filter: saturate(0.8);
}

.article-movement .article-movement-book-out-glow {
    width:1px;
    height:1px;
    position: absolute;
    left: 0;
    bottom: 0;
    box-shadow: 0 0 128px 128px rgba(255,77,77,0.5);
    filter: saturate(0.8);
}

@media screen and (max-width: 1700px) {
    .field {
        width:192px;
    }
}

@media screen and (max-width: 1500px) {
    .article-movement .transactions-holder {
        width:256px;
    }
    .field {
        width:128px;
    }
    .article-movement .book-button {
        right:288px;
    }
}