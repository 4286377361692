.expired-password-dialog {

}

.expired-password-dialog .close-icon {
    position: absolute;
    top:8px;
    right: 8px;
}

.expired-password-dialog .input-div {
    background-color:white;
    width:256px;
    height:40px;
    border:0px solid;
    border-radius:3px;
    margin-top:8px;
}

.expired-password-dialog .input-icon {
    float:left;
    margin:8px;
}

.expired-password-dialog .input-field {
    height:100%;
    border:0px;
    width:212px;
    border-radius:3px;
    padding-left:4px;
}