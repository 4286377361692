.order-item {
    width:98%;
    height:72px;
    margin:8px auto;
    text-align:left;
    overflow:hidden;
    cursor:auto;
}

.order-item .information-holder {
    width:90%;
    display:inline-block;
}

.order-item .information-holder li {
    width:13%;
    float:left;
    margin:4px 0 0 6px;
}

.order-item .information-holder li input {
    width:90%;
    background:#4D79FF;
    color:white;
    border:none;
    border-radius:3px;
    padding:1px 2px;
}

.order-item .item-icon {
    padding:8px 2px 0 0;
    margin:0;
    float:right;
    cursor: pointer;
}

.order-item .edit-icon {
    padding:8px 2px 0 0;
    margin:0;
    float:right;
    cursor: pointer;
}

.order-item .delete-icon {
    color:lightcoral;
    padding:8px 2px 0 0;
    margin:0;
    float:right;
    cursor: pointer;
}

.order-item .order-status {
    height:100%;
    width:4px;
    float:left;
    border-top-left-radius:4px;
    border-bottom-left-radius:4px;
}

.order-item .locked-info {
    margin-right:8px;
    color:grey;
    font-size:10px;
    float:right;
}

.order-item .item-note {
    position:absolute;
    margin:8px 0 0 70%;
    color:grey;
}

.order-item .document-positions-div p {
    font-size: 10px;
}