.automated-communication-item {
    width:98%;
    height:40px;
    margin:8px auto;
    text-align:left;
    overflow:hidden;
    cursor:pointer;
}

.automated-communication-item p {
    margin:10px 0 0 8px;
}

.automated-communication-item .actions-holder {
    float:right;
    margin-right:8px;
}

.automated-communication-item .icon {
    margin:8px 4px 0 0;
}

.automated-communication-item .check-icon {
    width:24px;
    height:24px;
    color:lightgreen;
}

.automated-communication-item .close-icon {
    width:24px;
    height:24px;
    color:lightcoral;
}