.draw-area {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: transparent;
}

.draw-area .draw-svg {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}

.draw-area .path {
    fill: none;
    stroke-linejoin: round;
    stroke-linecap: round;
    position: absolute !important;
    top:0 !important;
    right:0 !important;
    left:0 !important;
    bottom:0 !important;
    width:100%;
    height:100%;
}