.administration-settings {
    width:100%;
    height:100%;
    text-align:left;
}

.administration-settings h2 {
    margin:64px 0 0 32px;
}

.administration-settings .field {
    margin-left:32px;
    margin-bottom: 4px;
    width:160px;
}

.administration-settings .save-button {
    position: absolute;
    bottom:16px;
    right:16px;
}