.account-group-account-dialog {

}

.account-group-account-dialog .surrounder {
    padding:8px;
    width:256px;
    display:block;
    text-align:left;
}

.account-group-account-dialog .dialog-title {
    width:256px;
}

.account-group-account-dialog .field {
    width:100%;
    margin:8px auto;
}

.account-group-account-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.account-group-account-dialog .button {
    float:right;
    margin:8px 0;
}