.company-administration {
    width:100%;
    height:100%;
    text-align: left;
}

.company-administration h1 {
    margin:32px 0 0 32px;
}

.company-administration .surrounder {
    position: absolute;
    top:64px;
    bottom:0;
    right:0;
    left:0;
    overflow-y:auto;
    overflow-x: hidden;
    padding-bottom:32px;
}

.company-administration h2 {
    margin:16px 0 8px 32px;
}

.company-administration .textarea {
    width:90%;
    margin:8px 0 4px 32px;
}

.company-administration .input {
    margin:8px 0 4px 32px;
    width:160px;
}

.company-administration .file-selector {
    width:auto;
    margin-top:16px;
}

.company-administration .save-button {
    position: absolute;
    bottom:16px;
    right:16px;
}

.company-administration .logo-preview {
    height:32px;
    margin:4px;
}

.company-administration .clear-icon {
    margin-left:4px;
    cursor:pointer;
    color:#E8E8F0;
}

.company-administration .postbox-information-holder {
    margin:8px 0 0 32px;
}

.company-administration .postbox-button {
    margin-top:8px;
}