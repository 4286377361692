.history-panel {
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    z-index:500;
}

.history-panel ul {
    display:flex;
    height:100%;
}

.history-panel .button-li {
    width:64px;
}

.history-panel .toggle-button {
    width:64px;
    height:64px;
    margin-top:512px;
    display:flex;
    text-align:center;
}

.history-panel .history-icon{
    color:white;
    width:40px;
    height:40px;
    margin:auto;
}

.history-panel .content-holder {
    height:100%;
    overflow-x:hidden;
    overflow-y:auto;
}