.file-item {
    width:99%;
    min-height:32px;
    margin:8px auto;
    padding-bottom:2px;
    text-align: left;
}

.file-item .file-name {
    padding:8px;
}

.file-item .icon {
    float:right;
    margin:4px 8px 0 0;
    cursor: pointer;
}

.file-item .outlook-icon {
    width:18px;
    margin:6px 8px 0 0;
}

.file-item .delete-icon {
    color:lightcoral;
}

.file-item .info-icon {
    float:none;
    position: absolute;
    margin:-2px 0 0 8px;
}