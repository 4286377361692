.account-dialog {
    text-align:center;
}

.account-dialog .surrounder {
    padding:8px 16px;
    width:650px;
    display:block;
    text-align:left;
}

.account-dialog .close-icon {
     float:right;
     margin:8px 8px 0 0;
 }

.account-dialog .entries {
    width:100%;
}

.account-dialog th {
    width:20%;
}

.account-dialog tr {
    margin:0 8px;
    padding:0 8px;
    width:20%;
}

.account-dialog .add-fab {
    float:right;
}

.account-dialog .delete-icon {
    float:right;
}

.account-dialog .download-icon {
    float:right;
    margin:32px 32px 0 0;
}