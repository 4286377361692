.directory {
    width:100%;
    height:64px;
    margin:8px auto;
    text-align: center;
}

.directory .directory-icon {
    cursor: pointer;
    font-size: 48px;
    margin-top:12px;
    color:#FFD971;
}

.directory .directory-name {
    font-size:10px;
    width:100%;
    text-align: center;
}