.automated-communication-dialog {

}

.automated-communication-dialog .automated-communication-dialog-surrounder {
    padding:8px;
    width:720px;
    display:block;
    text-align:left;
}

.automated-communication-dialog .dialog-title {

}

.automated-communication-dialog .field {
    width:264px;
    margin:16px 8px 0 0;
}

.automated-communication-dialog .textarea {
    width:98%;
}

.automated-communication-dialog .save-button {
    float:right;
    margin:0 16px 16px 0;
}

.automated-communication-dialog .explanation-text {
    color:gray;
    margin:8px 0 0 0;
    font-size:12px;
}

.automated-communication-dialog .file-storage-holder {
    display:flex;
    margin-top:8px;
}

.automated-communication-dialog .file-storage-holder p {
    margin-top:2px;
}