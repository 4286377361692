.account-group-item {
    width:98%;
    margin:4px auto;
    text-align: center;
    padding-bottom:4px;
}

.account-group-item .top-field {
    height:40px;
}

.account-group-item .field {
    margin:10px 0 0 8px;
    float:left;
}

.account-group-item .delete-icon {
    float:right;
    margin:8px 8px 0 0;
}

.account-group-item .arrow-icon {
    float:right;
    margin:8px 8px 0 0;
    cursor:pointer;
}

.account-group-item .detailed-information {
    width:100%;
    margin:4px auto;
}