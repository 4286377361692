.generate-document-dialog {

}

.generate-document-dialog .surrounder {
    padding:8px;
    width:800px;
    display:block;
    text-align:left;
}

.generate-document-dialog .dialog-title {
    width:100%;
}

.generate-document-dialog .field {
    width:240px;
    margin:16px 8px 0 0;
}

.generate-document-dialog .checkbox-field {
    padding-top:0;
}

.generate-document-dialog .checkbox {
    padding-right:4px;
}

.generate-document-dialog .textarea {
    width:100%;
    margin:16px 0 0 0;
}

.generate-document-dialog .element-group {
    display:flex;
    position:relative;
}

.generate-document-dialog .part-field {
    width:80%;
}

.generate-document-dialog .part-icon {
    width:24px;
    height:24px;
    margin:16px 0 0 16px;
}

.generate-document-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.generate-document-dialog .no-customer-found-text {
    color:red;
    font-size:10px;
}

.generate-document-dialog .add-attribute-button {
    width:256px;
    margin: 8px auto;
}

.generate-document-dialog .button {
    float:right;
    margin:8px 0;
}

.generate-document-dialog .icon {
    margin-left:8px;
}

.generate-document-dialog .inactive {
    color:gray;
    cursor:not-allowed;
}

.generate-document-dialog .inactive:hover {
    opacity:1;
}

.generate-document-dialog .button-surrounder {
    width: 100%;
}

.generate-document-dialog .add-file-item {
    width:100%;
    height:32px;
    text-align: center;
    margin:4px auto;
    cursor: pointer;
}

.generate-document-dialog .add-file-icon {
    margin:4px auto;
}

.generate-document-dialog .add-file-icon:hover {
    opacity: 1;
}

.generate-document-dialog .document-category {
    margin:4px auto;
    padding:4px;
}

.generate-document-dialog .file-item {
    margin:4px auto;
}

.generate-document-dialog .header-content {
    height:24px;
    padding:4px 0 0 4px;
}

.generate-document-dialog .toggle-icon {
    float:right;
}

.generate-document-dialog .document-kind-holder {
    width:100%;
    display:flex;
}

.generate-document-dialog .status-field {
    float: left;
    margin:8px 8px 0 0;
}

.generate-document-dialog .position-display-switch {
    margin:0 0 0 8px;
}

.generate-document-dialog .position-display-holder {
    text-align:center;
    margin:0;
    padding:0;
    width:100%;
}

.generate-document-dialog .left-holder {
    width:3.5%;
    display:flex;
    cursor:pointer;
}

.generate-document-dialog .left-holder-start {
    width:3.5%;
    display:flex;
    cursor:pointer;
}

.generate-document-dialog .position-navigation-holder {
    display:inline-block;
}

.generate-document-dialog .navigation-item {
    display: inline-block;
}

.generate-document-dialog .position-navigation-icon {
    margin:auto;
    width:48px;
    height:48px;
    color:#4D79FF;
    cursor:pointer;
}

.generate-document-dialog .position-navigation-icon:hover {
    color:#2B4591;
}

.generate-document-dialog .position-info {
    margin:8px;
}

.generate-document-dialog .quick-actions {
    position:fixed;
    top:32px;
    right:32px;
    width:256px;
    padding:8px;
    z-index:1000;
}

.generate-document-dialog .max-icon {
    width:32px;
    height:32px;
    margin-bottom:-10px;
}

.generate-document-dialog .xml-form-group {
    float:right;
    margin:8px 0;
}

@media screen and (min-width: 1600px) {
    .generate-document-dialog .surrounder {width:1500px;}
    .generate-document-dialog .meta-information-holder {padding:8px;width:48%;float:left;}
    .generate-document-dialog .position-content-holder {padding:8px;width:48%;float:right;height:680px;}
    .generate-document-dialog .quick-actions {display:none;}
    .generate-document-dialog .position-holder {height:570px;}
    .generate-document-dialog .positions {overflow:auto;height:100%;}
    .generate-document-dialog .button-holder {position:absolute;margin-top:64px;width:720px;text-align:right;}
}