.tool-button {
    height:37px;
    cursor:pointer;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius:4px;
}

.main-button {
    background:#4D79FF;
}

.negative-button {
    background:#FF4D4D !important;
}

.secondary-button {
    background:rgba(77,121,255,0.5);
    box-shadow:none !important;
}

.secondary-button:hover {
    background:rgba(77,121,255,1);
    box-shadow:none !important;
}

.tool-button p {
    font-weight:bold;
    padding:16px;
}

.tool-button:hover {
    box-shadow:0 0 24px rgba(77,121,255,0.8);
}

.negative-button:hover {
    box-shadow:0 0 24px rgba(255,77,77,0.8) !important;
}