.batch-item-small {
    padding:2%;
    position: relative;
    width:96%;
    margin-top:8px;
}

.batch-item-small .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.batch-item-small .batch-nr {
    font-weight: bold;
    margin: 0;
}

.batch-item-small .batch-total {
    font-weight: bold;
    margin: 0;
}

.batch-item-small .information-holder {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.batch-item-small .whole-li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    border-bottom: 1px solid #ccc;
    padding: 8px 0;
}

.batch-item-small .location-name {
    flex-grow: 1;
    margin-right: 16px;
}

.batch-item-small .inventory-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.batch-item-small .input {
    width: 60px;
    margin-left: 8px;
}

.batch-item-small .edit-icon {
    cursor: pointer;
    margin-left: 8px;
}
