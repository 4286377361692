.sent-email-dialog {

}

.sent-email-dialog .surrounder {
    padding:8px;
    width:768px;
    display:block;
    text-align:left;
}

.sent-email-dialog .dialog-title {
    width:256px;
}

.sent-email-dialog .field {
    width:256px;
    margin: 8px 0;
}

.sent-email-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.sent-email-dialog .email-to {
    margin-left:8px;
}

.sent-email-dialog .email-content {
    box-shadow: inset 0 0 5px 1px rgba(0,0,0,0.1);
    padding:8px;
    margin-top:8px;
}

.sent-email-dialog .message {
    width:100%;
}

.sent-email-dialog .signature {
    width:100%;
    margin-top:18px;
}

.sent-email-dialog .attachment-container {
    margin-left:8px;
}

.sent-email-dialog .attachments {
    width:100%;
    margin-top:8px;
}

.sent-email-dialog .notes-content {
    margin:8px 0 0 8px;
}

.sent-email-dialog .notes {
    width:100%;
    margin-top:8px;
}