.pdf-menu-bar {
    width:512px;
    bottom:64px;
    margin-left:40px;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
    height:48px;
    border-radius: 4px;
    position: fixed;
    background-color: black;
    opacity:0.5;
    z-index: 5000;
}

.pdf-menu-bar:hover {
    opacity:1;
}

.pdf-menu-bar ul {
    width:90%;
    margin:6px auto 0 auto;
    display: block;
}

.pdf-menu-bar ul li {
    display: inline-block;
    margin:0 8px;
}

.pdf-menu-bar .navigate-icon-holder {
    width:32px;
    height:32px;
    background: white;
    border-radius: 16px;
    cursor: pointer;
}

.pdf-menu-bar .navigate-icon {
    width:20px;
    height:20px;
    margin:6px 0 0 4px;
}

.pdf-menu-bar .prev {
    float:left;
}

.pdf-menu-bar .next {
    float:right;
}

.pdf-menu-bar .icon {
    width:32px;
    height:32px;
}

.pdf-menu-bar .page-p {
    color:white;
}

.pdf-menu-bar .drawing-settings {
    position: absolute;
    background: white;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
    padding:8px;
    border-radius: 4px;
    width:160px;
}

.pdf-menu-bar .color-picker-item {
    width:12px;
    height:12px;
    border-radius: 6px;
    margin:2px;
    float:left;
    border:1px solid black;
    cursor: pointer;
}

.pdf-menu-bar .stroke-size-input {
    float:left;
    width:64px;
}

.pdf-menu-bar .stroke-preview {
    float:left;
    border:1px solid black;
}