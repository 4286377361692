.document-toolbar {

}

.document-toolbar .side-menu {
    top:0;
    left:0;
    bottom:0;
    width:320px;
    position:absolute;
    justify-content: space-between;
    align-items:center;
    box-sizing: border-box;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
    z-index: 90;
    background-color: white;
}

.document-toolbar .icon-holder {
    cursor:pointer;
    position: absolute;
    bottom:32px;
    left:32px;
    width:80px;
    height:80px;
    border-radius: 40px;
    box-shadow: 0 0 6px 2px rgba(0,0,0,0.2);
}

.document-toolbar .icon-holder:hover .arrow-icon {
    opacity:0.8;
}

.document-toolbar .arrow-icon {
    font-size: 48px;
    margin-top:16px;
}

.document-toolbar .side-menu img {
    margin-top:64px;
}

.document-toolbar .navigation-items {
    position: absolute;
    right:0;
    width:100%;
    top:160px;
}

.document-toolbar .top-panel {
    position: fixed;
    z-index: 100;
    left:0;
    right:0;
    top:0;
    height:64px;
    background-color: rgba(255,255,255,0.8);
    display:none;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
}

.document-toolbar .top-panel i {
    font-size: 28px;
    float:right;
    margin:18px 18px 0 0;
}

.document-toolbar .navigation-items {

}

.document-toolbar .navigation-items .nav-item {
    margin-top:12px;
    right:0;
    width: 100%;
    height: 48px;
    text-align: left;
    display:inline-block;
}

.document-toolbar .navigation-items .nav-item:hover {
    background-color: lightgrey;
}

.document-toolbar .navigation-items h2 {
    padding:6px 0 0 15%;
}

.document-toolbar .navigation-items .active {
    background-color: lightgrey;
    box-shadow:inset 0 0 5px 1px rgba(0,0,0,0.5);
}

.document-toolbar .navigation-items .active a {
    color:white;
}

.document-toolbar .navigation-items .icon {
    font-size:inherit;
    margin-bottom:-4px;
}

.document-toolbar .navigation-items .icon:hover {
    opacity: 1;
}

.document-toolbar .navigation-items .inactive {
    color:gray;
}

.document-toolbar .navigation-items .inactive-nav-item {
    margin-top:12px;
    right:0;
    width: 100%;
    height: 48px;
    text-align: left;
    display:inline-block;
    cursor:not-allowed;
}

.document-toolbar .navigation-items .inactive-nav-item:hover {
    background-color: lightgray;
}

@media screen and (max-width: 1919px) {
    .document-toolbar .side-menu {
        width:256px;
    }
}

@media screen and (max-width: 1023px) {
    .document-toolbar .side-menu {
        width:0;
        overflow: hidden;
    }

    .document-toolbar .top-panel {
        display: block;
    }
}

