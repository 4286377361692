.document-position {
    margin:8px auto;
    text-align: center;
    display:block;
    border-radius:4px;
    position:relative;
    width:98%;
}

.document-position .content-holder {
    padding:8px;
}

.document-position .name {
    text-align:left;
}

.document-position .top-holder {
    width:100%;
    display:block;
    position:relative;
    height:64px;
}

.document-position .down {
    margin-top:0;
}

.document-position .position-count-holder {
    margin:8px 0 0 8px;
    float:left;
}

.document-position .position-p {
    margin:2px 0 0 0;
}

.document-position .batch-icon {
    width:32px;
    height:32px;
    margin:16px 0 0 8px;
    float:left;
}

.document-position .inactive {
    color:gray;
}

.document-position .triangle-icon {
    height:6px;
}

.document-position .position-field {
    width:100%;
    margin-top:8px;
}

.document-position .field-holder {
    width:100%;
    display:flex;
}

.document-position .small-position-field {
    width:128px;
    margin:8px 8px 0 0;
    text-align:left;
    float:left;
}

.document-position .medium-position-field {
    width:264px;
    margin:8px 8px 0 0;
    float:left;
}

.document-position .big-position-field {
    width:256px;
    margin:4px 0 0 8px;
    text-align:left;
}

.document-position .field-header {
    text-align:left;
    margin:8px 0 0 8px;
}

.document-position .check-icon {
    color:lightgreen;
    height:14px;
}

.document-position .close-icon {
    max-width: 25%;
    float:right;
    margin:0;
    cursor:pointer;
}

.document-position .active-checkbox {
    float:right;
    margin:0;
}

.document-position .state-select {
    float: right;
    margin:0 8px 0 0;
}

.document-position .position-state {
    width:4px;
    float:left;
    border-top-left-radius:4px;
    border-bottom-left-radius:4px;
    min-height: 100% !important;
    height: 100%;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
}

.document-position .info-icon {
    float:right;
}

.document-position .last-price-icon {
    float:left;
    margin:12px 8px;
}

.document-position .form-group {
    margin:0;
}

.document-position .checkbox-field {
    margin:0;
    padding:0;
}

.document-position .checkbox {
    margin:0;
}

.document-position label {
    margin:0;
    padding:0;
}

.document-position .original-inventory-count-label {
    float:left;
    padding:16px 0 0 8px;
}

.document-position .guide-price-checkbox {
    float:left;
    width:128px;
    margin:0;
    padding:0;
}

.document-position .MuiFormControlLabel-root {
    margin:0 !important;
    padding:0 !important;
    width:128px;
    height:32px;
}