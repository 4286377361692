.stock-taking-inventory {
    width:99%;
    margin:8px auto;
    text-align:center;
    cursor:pointer;
    height:32px;
}

.stock-taking-inventory:hover {
    opacity:0.8;
}

.stock-taking-inventory .content-ul {
    text-align:left;
}

.stock-taking-inventory .content-ul li {
    display:inline-block;
    width:33%;
}

.stock-taking-inventory .content-ul li p {
    padding:6px 0 0 4px;
}