.orders {
    width:100%;
    height:100%;
    text-align:left;
}

.orders h1 {
    margin:32px 0 0 32px;
}

.orders .search-input {
    float:left;
    margin:16px 0 0 16px;
}

.orders .header-bar {
    display:inline-block;
    position:relative;
    width:100%;
    margin-top:64px;
}

.orders .field {
    width:160px;
    margin-left:16px;
}

.orders .search-button {
    margin:8px 0 0 8px;
    float:left;
}

.orders .filter-select {
    margin:16px 0 0 16px;
}

.orders .download-icon {
    float:right;
    margin:32px 32px 0 0;
}

.orders .item-list {
    position: absolute;
    top:172px;
    bottom:0;
    right:0;
    left:0;
    overflow-y:auto;
}

.orders .upload-fab {
    position:absolute;
    bottom:32px;
    right:164px;
    background-color: #4D79FF;
}

.orders .add-fab {
    position:absolute;
    bottom:32px;
    right:80px;
}

.orders .pdf-fab {
    position:absolute;
    bottom:32px;
    right:164px;
    background-color: #4D79FF;
}

.orders .export-fab {
    position:absolute;
    bottom:32px;
    right:248px;
    background-color: #4D79FF;
}

.orders .info-icon {
    position:absolute;
    top:8px;
    right:8px;
}

.orders .filter-icon {
    margin:8px 0 0 16px;
    width:32px;
    height:32px;
}

.orders .filter-options {
    width:256px;
    display:inline-block;
    position:absolute;
    margin:16px 0 0 64px;
    padding:8px;
}

.orders .checkbox-field {
    padding:0;
    margin:0;
}

.orders .loading-holder {
    position:relative;
    width:100%;
    height:100%;
    display:flex;
    text-align:center;
}

.orders .load-icon {
    margin:32px auto 0 auto;
}

.orders .warning-icon {
    width:32px;
    height:32px;
    margin:16px 16px 0 0;
    float:right;
}

.orders .urgent-item-count {
    float:right;
    font-size: 16px;
    margin:8px 8px 0 0;
}

.orders .default-icon {
    float:left;
}

.orders .sort-options {
    margin:24px 0 0 8px;
    width:90%;
}

.orders .sort-options li {
    display:inline-block;
    margin-left:4px;
    width:13%;
}

.orders .sort-option {
    cursor:pointer;
    height:20px;
    padding:2px 4px 0 4px;
    text-align: center;
}

.orders .switch-holder{
    float:right;
    width:256px;
    max-width:19%;
    margin-top:16px;
}

.orders .switch-label {
    float:left;
    margin:0;
}

.orders .switch {
    float:left;
    margin:0;
}

.orders .info-extension {
    font-size:10px;
    color:grey;
    margin:0 8px;
    width:100%;
    float:left;
}

.orders .position-top-holder {
    width:100%;
    height:64px;
    display:inline-block;
    margin:8px 32px;
}

.orders .position-top-holder li {
    width:128px;
    text-align:left;
    margin-left:8px;
    display:inline-block;
}