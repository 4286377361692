.side-menu {
    top:0;
    left:0;
    bottom:0;
    width:96px;
    position:absolute;
    justify-content: space-between;
    align-items:center;
    box-sizing: border-box;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
    z-index: 90;
    overflow:hidden;
    background:#FFFFFF;
}

.side-menu .extend-icon {
    position:absolute;
    bottom:4px;
    right:4px;
    width:16px;
    height:16px;
}

.side-menu .extend-icon:hover {
    color:#4D79FF;
    filter: drop-shadow(0 0 8px rgba(77,121,255,0.8));
}

.side-menu .surrounder {
    width:320px;
}

.side-menu .icon {
    font-size:32px;
    color:#525367;
}

.side-menu .settings-nav-item {
    font-size:32px;
    position: absolute;
    bottom:64px;
    left:32px;
    display:flex;
}

.side-menu .icon-holder:hover .arrow-icon {
    opacity:0.8;
}

.side-menu .arrow-icon {
    font-size: 48px;
    margin-top:16px;
}

.side-menu .module-icon {
    position:absolute;
    top:52px;
    display:flex;
    width:32px;
    height:32px;
}

.side-menu .module-button {
    opacity:0;
    margin:160px auto 0 auto;
    width:160px;
}

.side-menu .top-panel {
    position: fixed;
    z-index: 100;
    left:0;
    right:0;
    top:0;
    height:64px;
    display:none;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
}

.side-menu .top-panel i {
    font-size: 28px;
    float:right;
    margin:18px 18px 0 0;
}

.side-menu .navigation-items {
    text-align:left;
    position: absolute;
    left:32px;
    top:30%;
    width:256px;
}

.side-menu .navigation-items li {
    margin-top:24px;
    display:flex;
}

.side-menu h2 {
    color:#525367;
    opacity: 0;
    float:right;
    margin:2px 0 0 32px;
}

.side-menu .active .icon {
    color:#4D79FF;
    filter: drop-shadow(0 0 8px rgba(77,121,255,0.8));
}

.side-menu .navigation-items li:hover .icon {
    color:#4D79FF;
    filter: drop-shadow(0 0 8px rgba(77,121,255,0.8));
}

.side-menu .company-website {
    text-align:left;
    position:absolute;
    bottom:32px;
    left:32px;
    font-size: 10px;
    width:256px;
    opacity:0;
}

.side-menu .settings-nav-item:hover .icon {
    color:#4D79FF;
    filter: drop-shadow(0 0 8px rgba(77,121,255,0.8));
}

.side-menu .side-menu:hover .company-website {
    opacity:1;
}