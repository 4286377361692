.send-email-dialog {

}

.send-email-dialog .surrounder {
    padding:8px;
    width:768px;
    display:block;
    text-align:left;
}

.send-email-dialog .dialog-title {
    width:256px;
}

.send-email-dialog .field {
    width:256px;
    margin:8px 0 0 8px;
}

.send-email-dialog .part-field {
    width:80%;
}

.send-email-dialog .part-icon {
    width:24px;
    height:24px;
    margin:16px 0 0 16px;
}

.send-email-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.send-email-dialog .no-customer-found-text {
    color:red;
    font-size:10px;
}

.send-email-dialog .add-attribute-button {
    width:256px;
    margin: 8px auto;
}

.send-email-dialog .subject {
    margin-left:8px;
}

.send-email-dialog .message-input {
    width:98%;
}

.send-email-dialog .button {
    float:right;
    margin:8px 0;
}

.send-email-dialog .icon {
    margin-left:8px;
}

.send-email-dialog .inactive {
    color:gray;
    cursor:not-allowed;
}

.send-email-dialog .inactive:hover {
    opacity:1;
}

.send-email-dialog .clear-exhibitor-checkbox {
    margin:8px;
}

.send-email-dialog .attachment {
    float:left;
}

.send-email-dialog .remove-icon {
    color:#4D79FF;
}

.send-email-dialog .warning-icon {
    color:#FF4D4D;
}

.send-email-dialog .receiver-informations {
    display:flex;

}

.send-email-dialog .attachments-holder {
    margin:8px 0 0 8px;
    display:flex;
}

.send-email-dialog .attachment-info {
    margin:8px 0 0 8px;
}