.footer {
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    height:64px;
}

.footer p {
    margin-top:20px;
    color:white;
}