.automated-communication-setting-dialog {

}

.automated-communication-setting-dialog .surrounder {
    padding:8px;
    width:720px;
    display:block;
    text-align:left;
}

.automated-communication-setting-dialog .dialog-title {
    width:100%;
}

.automated-communication-setting-dialog .field {
    width:256px;
    margin:16px 8px 0 0;
}

.automated-communication-setting-dialog .small-field {
    width:128px;
}

.automated-communication-setting-dialog .textarea {
    width:98%;
}

.automated-communication-setting-dialog .save-button {
    float:right;
    margin:0 16px 16px 0;
}

.automated-communication-setting-dialog .explanation-text {
    color:gray;
    margin:8px 0 0 0;
    font-size:12px;
}

.automated-communication-setting-dialog .automated-communication-setting {
    width:100%;
    height:32px;
}

.automated-communication-setting-dialog .small-info {
    margin-top:4px;
    color:gray;
    font-size:11px;
}

.automated-communication-setting-dialog .switch-label {
    margin-top:16px;
    float:right;
}