.batches-dialog {

}

.batches-dialog .dialog-title {
    width:100%;
}

.batches-dialog .field {
    margin:8px;
}

.batches-dialog .save-button {
    float:right;
    margin:8px;
}

.batches-dialog .close-icon {
    float:right;
    margin:16px 16px 0 0;
}

.batches-dialog .transactions-holder {
    width:100%;
    max-height:256px;
    overflow-y:auto;
}

.batches-dialog h3 {
    margin:8px 0;
}

.batches-dialog .batch-inventory-item {
    width:100%;
    height:32px;
    margin-top:4px;
}

.batches-dialog .batch-inventory-item p {
    padding:8px 0 0 8px;
}

.batches-dialog .batch-inventory-item .edit-icon {
    float:right;
    padding:6px 6px 0 0;
}

.batches-dialog .small-input-field{
    width:48px;
    margin-top:-2px;
    background:#525367;
    color:white;
    border:none;
    border-radius:3px;
    padding:1px 2px;
}