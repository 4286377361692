.history-entry {
    width:100%;
    height:64px;
    text-align:left;
    overflow:hidden;
    border-bottom:1px solid lightgrey;
}

.history-entry .information-text {
    padding:4px 0 0 8px;
    width:90%;
}

.history-entry .date-time {
    padding:4px 8px 0 0;
    color:gray;
    font-size:10px;
    text-align:right;
}