.reporting-info-dialog {

}

.reporting-info-dialog .surrounder {
    width:600px;
}

.reporting-info-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.reporting-info-dialog .paragraph {
    text-align:justify;
}

.reporting-info-dialog h3 {
    margin:16px 0;
}