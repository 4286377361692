.master-data-content {
    width:100%;
    height:100%;
    text-align:left;
}

.master-data-content .header-bar {
    margin-top:64px;
    display:flex;
}

.master-data-content .search-input {
    margin:16px 0 0 16px;
}

.master-data-content .search-select {
    margin:16px 0 0 16px;
}

.master-data-content .search-button {
    margin:16px 0 0 16px;
}

.master-data-content .download-icon {
    float:right;
    margin:32px 32px 0 0;
}

.master-data-content .item-list {
    position: absolute;
    top:160px;
    bottom:0;
    right:0;
    left:0;
    overflow-y:auto;
}

.master-data-content .upload-fab {
    position:absolute;
    bottom:32px;
    right:160px;
    background-color: #4D79FF;
}

.master-data-content .add-fab {
    position:absolute;
    bottom:32px;
    right:80px;
}

.master-data-content .info-icon {
    position:absolute;
    top:8px;
    right:8px;
}