.email-panel {
    position:absolute;
    top:15%;
    right:1%;
    bottom:2%;
    width:24%;
    z-index:10;
}

.email-panel .panel-header {
    margin-top:32px;
}

.email-panel .email-fab {
    position: absolute;
    right:32px;
    bottom:32px;
}

.email-panel .email-attachment-count {
    position: absolute;
    background-color:#FF4D4D;
    width:24px;
    height:24px;
    border-radius: 12px;
    margin:0 -32px -32px 0;
    font-weight: bold;
}

.email-panel .search-bar {
    display: flex;
    left:10%;
    position: absolute;
    margin:16px auto;
    width: 80%;
    height:32px;
}

.email-panel .fa-search {
    position: absolute;
    color: #4D79FF;
    margin:8px 0 0 8px;
    height:100%;
    font-size: 16px;
    text-align: center;
    background: transparent;
}

.email-panel .search-input {
    box-shadow:inset 0 0 5px 1px rgba(0,0,0,0.5);
    width: 100%;
    height:100%;
    outline: none;
    border-radius: 16px;
    border:none;
    padding-left:32px;
}

.email-panel .search-results {
    overflow-y: auto;
    position: absolute;
    top:128px;
    left:0;
    right:0;
    bottom:0;
}

@media screen and (max-width: 1023px) {
    .email-panel {
        display: none;
    }
}