.file-system-context-menu {
    position: absolute;
    width:256px;
    height:auto;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
    z-index:3000;
    padding:4px 0;
}

.file-system-context-menu li {
    width:100%;
    cursor: pointer;
    text-align: left;
}

.file-system-context-menu li:hover {
    background-color: #5ac8fa;
}

.file-system-context-menu p {
    padding:4px;
}