.loading-screen {
    width:100%;
    height:100%;
    z-index:10000;
}

.loading-screen .loading-pulse {
    position:absolute;
    left:0;
    right:0;
    bottom:-10px;
    height:0;
    animation: pulse 2s ease-in infinite;
    box-shadow: 0 0 48px 48px rgba(77,121,255,0.3);
}

@keyframes pulse {
    0% {
        box-shadow: 0px 0px 4px 4px rgba(77,121,255,0.3);
    }
    50% {
        box-shadow: 0px 0px 96px 96px rgba(77,121,255,0.6);
    }
    100% {
        box-shadow: 0px 0px 4px 4px rgba(77,121,255,0.3);
    }
}