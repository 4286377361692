.item-dialog {

}

.item-dialog .surrounder {
    padding:8px;
    width:512px;
    display:block;
    text-align:left;
}

.item-dialog .close-icon {
     float:right;
     margin:8px 8px 0 0;
}

.item-dialog .dialog-title {
    width:256px;
}

.item-dialog .field {
    width:240px;
    margin:8px;
}

.item-dialog .multiline {
    width:496px;
}

.item-dialog .switch {
    width:200px;
    margin:16px 0 0 8px;
}

.item-dialog .add-attribute-button {
    width:256px;
    margin: 8px auto;
}

.item-dialog .button {
    margin:16px;
    float:right;
}

.item-dialog h3 {
    margin:8px;
}

.item-dialog .textarea {
    width:496px;
    margin:8px;
}

.item-dialog .address-actions-holder {
    width:100%;
    display:flex;
}

.item-dialog .add-address-button {

}

.item-dialog .delete-icon {
    float:right;
    margin:8px 8px 0 0;
}

.item-dialog .address-holder {
    margin-top:8px;
}

.item-dialog .info-field {
    margin:8px 0 0 8px;
    font-size:11px;
    color:gray;
}