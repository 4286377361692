.rename-directory-dialog {

}

.rename-directory-dialog .dialog-title {
    width:256px;
}

.rename-directory-dialog .field {
    width:100%;
    margin:0 auto;
}

.rename-directory-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.rename-directory-dialog .button {
    float:right;
    margin:8px 0;
}

.rename-directory-dialog .pdf-extension {
    float:right;
    margin:4px 0 0 2px;
}