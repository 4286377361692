.multiple-mail-adresses-context-menu {
    position: fixed;
    width:360px;
    height:auto;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 0 8px 2px rgba(0,0,0,0.3);
    z-index:3100;
    padding:4px 0;
    overflow:hidden;
}

.multiple-mail-adresses-context-menu li {
    width:100%;
    height:18px;
    cursor: pointer;
    text-align: left;
}

.multiple-mail-adresses-context-menu li:hover {
    background-color: #5ac8fa;
}

.multiple-mail-adresses-context-menu p {
    margin:4px;
}

.multiple-mail-adresses-context-menu #multiple-mail-adresses-context-menu-tooltip {
    z-index:3200 !important;
}