.position-dialog {

}

.position-dialog .surrounder {
    padding:8px;
    width:512px;
    display:block;
    text-align:left;
}

.position-dialog .dialog-title {
    width:100%;
}

.position-dialog .field {
    margin:8px;
}

.position-dialog .button {
    float:right;
    margin:8px 0;
}

.position-dialog .secondary-button {
    margin-right:8px;
}

.position-dialog .add-condition-button {
    float:left;
    margin:8px 0;
}

.position-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.position-dialog .filter-option-list {
    width:100%;
    min-height:32px;
    display:flex;
}

.position-dialog .switch-label {
    margin:8px;
}

.position-dialog .design-line {
    width:80%;
    margin:8px auto;
    border:none;
    background:lightgrey;
}

.position-dialog .section-header {
    margin:8px 0 16px 16px;
}

.position-dialog ul li {
    margin-top:4px;
}

.position-dialog .textarea {
    width:100%;
    margin:8px 0 0 0;
}

.position-dialog .position-field {
    margin:8px 8px 0 0;
    width:192px;
}