.list-item {
    width:98%;
    height:72px;
    margin:8px auto;
    text-align:left;
    overflow:hidden;
}

.list-item .item-nr {
    color:gray;
    padding:8px 0 0 8px;
}

.list-item .item-name {
    margin:4px 0 0 8px;
}

.list-item .edit-icon {
    margin:8px 4px 0 0;
    float:right;
    cursor: pointer;
}

.list-item .add-connected-icon {
    margin:8px 4px 0 0;
    float:right;
    cursor: pointer;
}

.list-item .remove-connected-icon {
    margin:8px 4px 0 0;
    float:right;
    cursor: pointer;
}

.list-item .delete-icon {
    color:lightcoral;
    margin:8px 8px 0 0;
    float:right;
    cursor: pointer;
}

.list-item .pdf-icon {
    margin:-8px 0 0 0;
}

.list-item .remove-icon {
    margin:-8px 0 0 0;
    color:gray;
}

.list-item .order-status {
    height:100%;
    width:4px;
    float:left;
    border-top-left-radius:4px;
    border-bottom-left-radius:4px;
}

.list-item .small-info {
    color:grey;
    font-size:10px;
}

.list-item .locked-info {
    margin-right:8px;
    color:grey;
    font-size:10px;
    float:right;
}

.list-item .document-positions-div {
    position:absolute;
    margin:8px 0 0 50%;
}

.list-item .item-note {
    position:absolute;
    margin:8px 0 0 70%;
    color:grey;
}