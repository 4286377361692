.settings {
    width:100%;
    height:100%;
    text-align: left;
}

.settings .input {
    margin-left:16px;
    margin-top:64px;
}

.settings .account-information {
    margin:8px 0 0 16px;
}

.settings .postbox-button {
    margin:64px 16px;
}

.settings .save-button {
    position: absolute;
    bottom:16px;
    right:16px;
}

.settings .message-input {
    width:94%;
    margin-left:16px;
    margin-top:64px;
}