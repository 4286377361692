.export-files-dialog {

}

.export-files-dialog .surrounder {
    padding:8px;
    width:720px;
    display:block;
    text-align:left;
}

.export-files-dialog .dialog-title {
    width:100%;
}

.export-files-dialog .field {
    margin:8px;
}

.export-files-dialog .button {
    float:right;
    margin:8px 0;
}

.export-files-dialog .secondary-button {
    margin-right:8px;
}

.export-files-dialog .add-condition-button {
    float:left;
    margin:8px 0;
}

.export-files-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.export-files-dialog .filter-option-list {
    width:100%;
    min-height:32px;
    display:flex;
}

.export-files-dialog .switch-label {
    margin:8px;
}

.export-files-dialog .design-line {
    width:80%;
    margin:8px auto;
    border:none;
    background:lightgrey;
}

.export-files-dialog .section-header {
    margin:8px 0 16px 16px;
}