.parts-list-dialog {

}

.parts-list-dialog .surrounder {
    width:600px;
    text-align:left;
    display:inline-block;
}

.parts-list-dialog .dialog-title {
    width:100%;
}

.parts-list-dialog .parts-list-name {
    margin:8px;
}

.parts-list-dialog .components-results-surrounder {
    width:100%;
}

.parts-list-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.parts-list-dialog .add-attribute-button {
    width:100%;
    margin: 8px auto;
}

.parts-list-dialog .button {
    float:right;
    margin:8px;
}

.parts-list-dialog .icon {
    margin-left:8px;
}

.parts-list-dialog .inactive {
    color:gray;
    cursor:not-allowed;
}

.parts-list-dialog .inactive:hover {
    opacity:1;
}

.parts-list-dialog .components {
    width:48%;
    margin-left:1%;
    float:left;
}

.parts-list-dialog .results {
    width:48%;
    margin-right:1%;
    float:right;
}