.add-entry-dialog {

}

.add-entry-dialog .surrounder {
    padding:8px 16px;
    width:512px;
    display:block;
    text-align:left;
}

.add-entry-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.add-entry-dialog .dialog-title {
    width:256px;
}

.add-entry-dialog .field {
    width:240px;
    margin:8px;
}

.add-entry-dialog .button {
    position: absolute;
    bottom:16px;
    right:16px;
}