.file-dialog {

}

.file-dialog .close-icon {
    position: absolute;
    top:8px;
    right: 8px;
}

.file-dialog .errormessage {
    color:lightcoral;
    font-size:12px;
    margin:8px 0;
}