.article-information {
    position:absolute;
    left:1%;
    bottom:2%;
    right:26%;
    top:76%;
}

.article-information .fields-holder {
    display: inline-block;
    margin: 16px 0 0 16px;
    overflow-y:auto;
    width:98%;
}

.article-information .batches-holder {
    position:absolute;
    top:48px;
    left:8px;
    bottom:0;
    right:8px;
    overflow-y:auto;
}

.article-information .batch-holder {
    width:98%;
    height:32px;
    margin:4px auto;
    padding:2px;
}

.article-information .field {
    width:128px;
    float:left;
}

.article-information #articleNr {
    position: absolute;
}

.article-information .big-field {
    width:256px;
    float:left;
}

.article-information .tool-text-pair {
    text-align: left;
}

.article-information .tool-text-pair .value {
    margin-top:2px;
}

.article-information .tool-text-pair .text {
    color:gray;
    font-size:11px;
}

@media screen and (max-width: 1023px) {
    .article-information {
        display: none;
    }
}