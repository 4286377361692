.module-overview {
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    display:flex;
    align-items: center;
    justify-content: center;
}

.module-overview .modules {

}

.module-overview .module {
    width:448px;
    height:96px;
    margin-top:16px;
    border-radius: 10px;
    cursor: pointer;
    display:flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.module-overview .deactivated-module {
    cursor:not-allowed !important;
}

.module-overview .module:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.4);
}

.module-overview .module-icon {
    float:left;
    margin-left:32px;
    width:32px;
    height:32px;
}

.module-overview .module:hover {

}

.module-overview .module-name {
    float:left;
    margin-left:32px;
}