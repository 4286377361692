.warehouse-actions-menu {
    position:absolute;
    left:1%;
    bottom:85%;
    right:28%;
    height:32px;
    z-index: 20;
}

.warehouse-actions-menu a {
    position:relative;
    display:inline-block;
    font-size: 18px;
    bottom:0;
    color:#202124;
    height:32px;
}

.warehouse-actions-menu .inventory-menu a {
    width:20%;
}

.warehouse-actions-menu .order-management-menu a {
    width:50%;
}

.warehouse-actions-menu .accounting-menu a {
    width:50%;
}

.warehouse-actions-menu .master-data-menu a {
    width:14.2%;
}

.warehouse-actions-menu .administration-menu a {
    width:33.3%;
}

.warehouse-actions-menu .settings-menu a {
    width:50%;
}

.warehouse-actions-menu a:hover {
    color:#4D79FF;
}

.warehouse-actions-menu a:after {
    left:50%;
    background: none repeat scroll 0 0;
    bottom:0;
    content:'';
    display:block;
    height:2px;
    position:absolute;
    background:#4D79FF;
    transition:width 0.2s ease 0s, left 0.2s ease 0s;
    width:0;
}

.warehouse-actions-menu a:hover:after {
    bottom:0;
    width: 100%;
    left:0;
    color:#4D79FF;
}

.warehouse-actions-menu .active:after {
    bottom:0;
    width:100%;
    left:0;
    color:#4D79FF;
}

.warehouse-actions-menu .active {
    color:#4D79FF;
    font-weight: bold;
}

@media screen and (max-width: 1023px) {
    .warehouse-actions-menu {
        left:1%;
        right:1%;
        bottom:75%;
    }
}