.order-management {
    width:100%;
    height:100%;
    text-align: left;
}

.order-management h1 {
    margin:32px 0 0 32px;
}

.order-management .info-icon {
    position:absolute;
    top:8px;
    right:8px;
}

.order-management .side-panel {
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    width:256px;
    text-align: center;
    box-shadow: 2px 0 3px 0 rgba(0,0,0,0.2);
    overflow: hidden;
}

.order-management .content-panel {
    position: absolute;
    bottom:0;
    right:0;
    top:0;
    left:256px;
}

.order-management hr {
    width:80%;
    margin:32px auto;
    height: 1px;
    border: none;
    background-color: gray;
}

.order-management .inventory-actions {
    width:90%;
}

.order-management .article-input {
    margin:16px 0 0 32px;
    float:left;
}

.order-management .count-input {
    margin:16px 0 0 16px;
    width:96px;
    float:left;
}

.order-management .net-price-input {
    margin:16px 0 0 16px;
    width:96px;
    float:left;
}

.order-management .switch {
    margin:32px 0 0 16px;
    float:left;
}

.order-management .fields {
    margin:16px 32px;
    display: inline-grid;
    float:left;
    width:90%;
}

.order-management .field {
    margin-top:8px;
    float:left;
    margin-right:16px;
}

.order-management .field-checkbox {
    width:140px;
}

.order-management .field-text-field {
    width:140px;
}

.order-management .book-button {
    position: absolute;
    bottom:16px;
    right:16px;
}

@media screen and (max-width: 1279px) {
    .order-management .content-panel {
        left:192px;
    }

    .order-management .side-panel {
        width:192px;
    }
}

@media screen and (max-width: 1024px) {
    .order-management .content-panel {
        left:256px;
    }

    .order-management .side-panel {
        width:256px;
    }
}

@media screen and (max-width: 720px) {
    .order-management .content-panel {
        left:0;
    }

    .order-management .side-panel {
        width:0;
    }
}
