.user-dialog {

}

.user-dialog .surrounder {
    padding:8px 16px;
    width:256px;
    display:block;
    text-align:left;
}

.user-dialog .dialog-title {
    width:256px;
}

.user-dialog .field {
    width:256px;
    margin:8px auto;
}

.user-dialog .add-attribute-button {
    width:256px;
    margin: 8px auto;
}

.user-dialog .button {
    float:right;
    margin:16px 0;
}