.stock-taking-inventory-dialog {
    display:inline-block;
}

.stock-taking-inventory-dialog .field {
    margin:8px;
}

.stock-taking-inventory-dialog .save-button {
    margin:8px;
}

.stock-taking-inventory-dialog .batches-holder {
    width:100%;
}

.stock-taking-inventory-dialog .batches-holder-title {
    margin:8px 0 8px 16px;
}

.stock-taking-inventory-dialog .add-stock-taking-inventory {
    cursor:pointer;
    text-align:center;
    height:32px;
}

.stock-taking-inventory-dialog .add-stock-taking-inventory p {
    padding-top:6px;
}

.stock-taking-inventory-dialog .add-paper-button {
    width:100%;
    height:32px;
    margin:8px auto;
    text-align:center;
    overflow:hidden;
    cursor:pointer;
}

.stock-taking-inventory-dialog .add-icon {
    margin-top:4px;
}

.stock-taking-inventory-dialog .info-text {
    color:lightgray;
    font-size: 11px;
    margin:8px;
}