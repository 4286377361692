.inventory {
    width:100%;
    height:100%;
    text-align: left;
}

.inventory h1 {
    margin:32px 0 0 32px;
}

.inventory .info-icon {
    position:absolute;
    top:8px;
    right:8px;
}

.inventory .side-panel {
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    width:256px;
    text-align: center;
    box-shadow: 2px 0 3px 0 rgba(0,0,0,0.2);
    overflow: hidden;
}

.inventory .content-panel {
    position: absolute;
    bottom:0;
    right:0;
    top:0;
    left:256px;
}

.inventory hr {
    width:80%;
    margin:32px auto;
    height: 1px;
    border: none;
    background-color: gray;
}

.inventory .inventory-actions {
    width:90%;
}

.inventory .article-input {
    margin:16px 0 0 32px;
    float:left;
}

.inventory .count-input {
    margin:16px 0 0 16px;
    width:96px;
    float:left;
}

.inventory .net-price-input {
    margin:16px 0 0 16px;
    width:96px;
    float:left;
}

.inventory .fields {
    margin:16px 32px;
    display: inline-grid;
    float:left;
}

.inventory .field {
    margin-top:8px;
    float:left;
    margin-right:16px;
}

.inventory .field-checkbox {
    width:140px;
}

.inventory .field-text-field {
    width:140px;
}

@media screen and (max-width: 1279px) {
    .inventory .content-panel {
        left:192px;
    }

    .inventory .side-panel {
        width:192px;
    }
}

@media screen and (max-width: 1024px) {
    .inventory .content-panel {
        left:256px;
    }

    .inventory .side-panel {
        width:256px;
    }
}

@media screen and (max-width: 720px) {
    .inventory .content-panel {
        left:0;
    }

    .inventory .side-panel {
        width:0;
    }
}
