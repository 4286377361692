.pdf-dialog {
    background: transparent;
}

.pdf-dialog .surrounder {
    text-align: center;
    background-color: rgb(242, 242, 242);
}

.pdf-dialog-dialog .dialog-title {
    width:100%;
}

.pdf-dialog .loading-div {
    width:595px;
    height:842px;
}

.pdf-dialog .loading-div span {
    margin-top: 50%;
}

.pdf-dialog .close-icon {
    position: absolute;
    top:8px;
    right:8px;
}

.pdf-dialog .test-button {
    position: absolute;
    top:50px;
    left:50%;
}