.login {
    position: absolute;
    left:0;
    width:100%;
    height:100%;
}

.login .login-div {
    width:384px;
    height:480px;
    margin:128px auto 0 auto;
    text-align:center;
    backdrop-filter: blur(10px);
}

.login .wm-logo {
    margin:32px 0;
    height:96px;
}

.login .input-div {
    background-color:white;
    width:256px;
    height:40px;
    border:0px solid;
    border-radius:3px;
    margin:0 auto 16px auto;
}

.login .input-icon {
    float:left;
    margin:8px;
}

.login .input-field {
    height:100%;
    border:0;
    width:212px;
    border-radius:3px;
    padding-left:4px;
}

.login .login-div .register-text {
    cursor:pointer;
}

.login .login-button {
    margin:32px auto;
    width:256px;
}

.login .company-website {
    position:absolute;
    left:16px;
    bottom:16px;
}

@media screen and (max-width: 720px) {
    .login .login-div {
        margin-top:64px;
        width:80%;
    }
}