.batch {
    padding:4px;
    margin-top:8px;
    display:block;
    text-align:left;
}

.batch .field {
    width:240px;
    margin:8px;
}

.batch .switch {
    width:200px;
    margin:16px 0 0 8px;
}

.batch .button {
    margin:16px;
    float:right;
}

.batch .panel {
    padding:8px 0;
}

.batch .under-panel {
    width:98%;
    margin:0 auto;
    padding:4px 0;
}

.batch h3 {
    margin:8px;
}

.batch .search-icon {
    padding:16px 0 0 16px;
}

.batch .factory-certificate-panel {
    width:100%;
    margin:4px auto;
    text-align: left;
    padding-bottom:4px;
}

.batch .factory-certificate-panel .top-field {
    height:40px;
}

.batch .factory-certificate-panel .expand-panel-title {
    margin:10px 0 0 8px;
    float:left;
    width:90%;
    height:40px;
}

.batch .factory-certificate-panel .add-remove-icon {
    float:right;
    margin:8px 8px 0 0;
    cursor:pointer;
}

.batch .icon-holder {
    display: block;
    margin:8px 0 0 8px;
    width:90%;
}