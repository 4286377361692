.accounting-management-system {
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background-color: #D9D9D9;
}

.accounting-management-system .content {
    left:320px;
    top:0;
    right:0;
    bottom:0;
    position: absolute;
}

@media screen and (max-width: 1919px) {
    .accounting-management-system .content {
        left:256px;
    }
}

@media screen and (max-width: 1023px) {
    .accounting-management-system .content {
        left:0;
    }
}