.attribute-item {
    width:256px;
    border-radius: 5px;
    margin:8px 0;
    text-align: center;
}

.attribute-item .close-icon {
    margin:8px 8px 0 0;
    float: right;
    color:gray;
}

.attribute-item .attribute-field {
    margin: 8px auto;
    width:90%;
    text-align: left;
}

.attribute-item .main-checkbox {
    float:left;
    margin-left:4px;
}