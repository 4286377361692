.account-item {
    width:98%;
    margin:4px auto;
    text-align: center;
}

.account-item .top-field {
    height:40px;
}

.account-item ul {
    width:100%;
}

.account-item li {
    float:left;
    width:33%;
}

.account-item .field {
    margin:8px 0 0 8px;
    float:left;
}

.account-item .book-icon {
    float:right;
    margin:8px 8px 0 0;
}

.account-item .detailed-information {
    width:100%;
    margin:4px auto;
}