.connected-orders-dialog {

}

.connected-orders-dialog .surrounder {
    padding:8px;
    width:720px;
    display:block;
    text-align:left;
}

.connected-orders-dialog .dialog-title {
    width:100%;
}

.connected-orders-dialog .field {
    margin:8px;
}

.connected-orders-dialog .button {
    float:right;
    margin:8px 0;
}

.connected-orders-dialog .secondary-button {
    margin-right:8px;
}

.connected-orders-dialog .add-condition-button {
    float:left;
    margin:8px 0;
}

.connected-orders-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.connected-orders-dialog .search-input {
    margin:16px 0 0 8px;
}

.connected-orders-dialog .search-select {
    margin:16px 0 0 16px;
    width:160px;
}

.connected-orders-dialog .search-button {
    margin:16px 0 0 16px;
}

.connected-orders-dialog .loading-holder {
    position:relative;
    width:100%;
    height:100%;
    display:flex;
    text-align:center;
}

.connected-orders-dialog .load-icon {
    margin:32px auto 0 auto;
}

.connected-orders-dialog .small-header {
    padding-left:8px;
}