.tool-text-field {
    min-height:40px;
}

.tool-text-field .MuiOutlinedInput-root .Mui-focused {

}

.tool-text-field .MuiInputLabel-root.Mui-focused {

}