.postbox-dialog {

}

.postbox-dialog .surrounder {
    padding:8px;
    width:512px;
    display:block;
    text-align:left;
}

.postbox-dialog .dialog-title {
    width:100%;
}

.postbox-dialog .field {
    width:240px;
    margin:8px;
}

.postbox-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.postbox-dialog .save-button {
    float:right;
    margin:8px;
}