.accounting-dashboard {

}

.accounting-dashboard h1 {
    margin:32px 0;
}

.accounting-dashboard h2 {
    margin:16px 0;
}

.accounting-dashboard p {
    margin:64px 0;
}

.accounting-dashboard a {
    color:blue;
}

.accounting-dashboard .video {
    width:640px;
    height:360px;
}

@media screen and (max-width: 1023px) {
    .accounting-dashboard .video {
        width:320px;
        height:180px;
    }
}