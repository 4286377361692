.document-file-system {
    width:100%;
    height:100%;
    text-align:center;
}

.document-file-system h1 {
    margin:32px 0 0 32px;
    text-align:left;
}

.document-file-system .info-icon {
    position:absolute;
    top:8px;
    right:8px;
}

.document-file-system .switch {
    margin:8px 0 0 16px;
    float:left;
}

.document-file-system .file-tree {
    position: absolute;
    top:128px;
    left:0;
    right:0;
    bottom:0;
    overflow-y:auto;
    overflow-x: hidden;
}

.document-file-system .directories-path {
    width:100%;
    height:64px;
    margin:8px 0 0 32px;
}

.document-file-system .path-directory {
    cursor: pointer;
    float:left;
    box-shadow: inset 0 0 5px 1px rgba(0,0,0,0.1);
    margin-top:8px;
    padding:8px;
}

.document-file-system .arrow-icon {
    color:black;
}

.document-file-system .arrow-icon:hover {
    opacity: 1;
}

.document-file-system .add-button {
    width:98%;
    margin:4px auto;
}

.document-file-system .directory-holder {
    width:100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(10, 10%);
}

.document-file-system .file-holder {
    width:100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(10, 10%);
}