.accounting {
    width:100%;
    height:100%;
    text-align:center;
}

.accounting h1 {
    margin:32px 0 0 32px;
    text-align:left;
}

.accounting .info-icon {
    position:absolute;
    top:8px;
    right:8px;
}

.accounting .switch {
    margin:8px 0 0 16px;
    float:left;
}

.accounting .account-tree {
    position: absolute;
    top:128px;
    left:0;
    right:0;
    bottom:0;
    overflow-y:scroll;
}

.accounting .add-group-button {
    width:98%;
    margin:4px auto;
}