.sent-email {
    width:100%;
    height:64px;
    background: rgb(250,244,233);
    background: linear-gradient(135deg, rgba(250,244,233,1) 0%, rgba(218,187,136,1) 100%);
    text-align: left;
    cursor: pointer;
}

.sent-email .email-to {
    padding:8px 0 0 8px;
}

.sent-email .email-subject {
    margin:8px 0 0 8px;
}

.sent-email .email-date {
    float:right;
    margin:8px 8px 0 0;
}