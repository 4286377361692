.automated-communication {
    width:100%;
    height:100%;
    text-align:left;
}

.automated-communication h1 {
    margin:32px 0 0 32px;
}

.automated-communication .add-fab {
    position:absolute;
    bottom:32px;
    right:128px;
}

.automated-communication .info-text {
    color:gray;
    font-size: 12px;
    padding:16px 32px 0 32px;
}

.automated-communication .automated-communication-list {
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:160px;
    overflow-y:auto;
}

.automated-communication .settings-icon {
    position:absolute;
    top:16px;
    right:16px;
    width:32px;
    height:32px;
}