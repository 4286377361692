.stock-taking {

}

.stock-taking h1 {
    margin:32px 0 0 32px;
    text-align:left;
}

.stock-taking .content-div {
    position:absolute;
    display:block;
    top:64px;
    left:0;
    right:0;
    bottom:0;
}

.stock-taking .stock-taking-inventories {
    position:absolute;
    top:32px;
    left:0;
    right:0;
    bottom:0;
}

.stock-taking .list-holder {
    position:absolute;
    top:80px;
    left:0;
    right:0;
    bottom:0;
    overflow-y:auto;
    overflow-x:hidden;
}

.stock-taking .stock-taking-button {
    position: absolute;
    right:32px;
    bottom:32px;
    width:210px;
}

.stock-taking .book-stock-taking-button {
    position: absolute;
    right:256px;
    bottom:32px;
}

.stock-taking .add-stock-taking-inventory {
    width:99%;
    margin:8px auto;
    text-align:center;
    cursor:pointer;
    height:32px;
}

.stock-taking .add-stock-taking-inventory:hover {
    opacity:0.8;
}

.stock-taking .add-icon {
    padding-top:4px;
}

.stock-taking .info-text {
    width:99%;
    margin:4px auto;
    color:lightgrey;
    font-size:11px;
}

.stock-taking .action {
    margin:8px;
}

.stock-taking .stock-taking-info {
    margin:64px 0 0 16px;
}