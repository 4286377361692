.search-transaction-dialog {

}

.search-transaction-dialog .surrounder {
    padding:16px;
    width:512px;
    display:block;
    text-align:left;
}

.search-transaction-dialog .dialog-title {
    width:100%;
}

.search-transaction-dialog .close-icon {
    position:absolute;
    top:32px;
    right:32px;
}

.search-transaction-dialog .searched-transactions {
    width:100%;
    max-height:512px;
    overflow-y:auto;
    margin:8px auto;
}

.search-transaction-dialog .field {
    margin:16px 8px 0 0;
}

.search-transaction-dialog .small-field {
    width:160px;
}

.search-transaction-dialog .sum-icon-excel {
    float:right;
    font-size:24px;
}