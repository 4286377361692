.reservation-dialog {

}

.reservation-dialog .surrounder {
    padding:8px 16px;
    width:512px;
    display:block;
    text-align:left;
}

.reservation-dialog .dialog-title {
    width:256px;
}

.reservation-dialog .field {
    width:256px;
    margin:8px auto;
}

.reservation-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.reservation-dialog .add-attribute-button {
    width:256px;
    margin: 8px auto;
}

.reservation-dialog .button {
    float:right;
    margin:8px 0;
}