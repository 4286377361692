.reporting {
    width:100%;
    height:100%;
    text-align: left;
}

.reporting h1 {
    margin:32px 0 0 32px;
}

.reporting .date-input {
    margin:16px 0 0 32px;
}

.reporting .add-attribute-button {
    float:right;
    margin:16px 16px 0 0;
}

.reporting .condition-list {
    position: absolute;
    top:160px;
    left:0;
    right:0;
    bottom:64px;
    overflow-y:auto;
}

.reporting .connection-operator {
    margin:2px 16px;
}

.reporting .report-button {
    position: absolute;
    bottom:16px;
    right:16px;
}

.reporting .info-icon {
    position:absolute;
    top:8px;
    right:8px;
}