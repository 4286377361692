.master-data-item {
    width:98%;
    height:64px;
    margin:8px auto;
    text-align: left;
}

.master-data-item .item-nr {
    color:gray;
    padding:8px 0 0 8px;
}

.master-data-item .item-name {
    margin:4px 0 0 8px;
}

.master-data-item .edit-icon {
    margin:8px 8px 0 0;
    float:right;
    cursor: pointer;
}

.master-data-item .delete-icon {
    color:lightcoral;
    margin:8px 8px 0 0;
    float:right;
    cursor: pointer;
}