.message-templates-dialog {
}

.message-templates-dialog .message-templates-dialog-surrounder {
    padding:8px;
    width:512px;
    display:block;
    text-align:left;
}

.message-templates-dialog .dialog-title {
    width:100%;
}

.message-templates-dialog .field {
    width:192px;
    margin:8px;
}

.message-templates-dialog .open-button {
    float:right;
    margin:0 8px 8px 0;
}

.message-templates-dialog .header {
    margin:8px;
}

.message-templates-dialog .preview-text {
    margin:8px;
}

.message-templates-dialog .preview-text p {
    margin:8px 0;
}