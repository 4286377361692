.user-item {
    width:98%;
    margin:8px auto;
    text-align: left;
}

.user-item .user-header {
    height:64px;
}

.user-item .user-content {
    padding-bottom:80px;
}

.user-item .arrow-icon {
    padding:20px 0 0 8px;
    float:left;
    cursor:pointer;
}

.user-item .username {
    padding:18px 0 0 8px;
    float:left;
}

.user-item .field {
    float:left;
    margin:16px 0 16px 16px;
}

.user-item hr {
    margin:0 auto;
    width:80%;
}

.user-item .save-icon {
    cursor:pointer;
}

.user-item .delete-icon {
    cursor:pointer;
    float:right;
    margin:16px;
    color:lightcoral;
}

.user-item .delete-icon-disabled {
    cursor:not-allowed;
    float:right;
    margin:16px;
    color:lightgray;
}