.article-report {
    width:100%;
    margin:4px auto;
    text-align: left;
}

.article-report .top-field {
    height:40px;
}

.article-report ul {
    width:100%;
}

.article-report li {
    float:left;
    width:25%;
}

.article-report .field {
    padding:12px 0 0 8px;
    float:left;
}

.article-report .arrow-icon {
    float:right;
    padding:8px 8px 0 0;
    cursor:pointer;
}

.article-report table {
    width:100%;
    margin:4px auto;
    padding:0 8px;
}