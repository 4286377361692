.condition-item {
    box-shadow:0 0 5px 1px rgba(0,0,0,0.3);
    width:98%;
    margin:8px auto;
    border-radius: 5px;
    height:96px;
}

.condition-item * {
    float:left;
}

.condition-item .input-field {
    margin:16px 0 0 16px;
    min-width:128px;
}

.condition-item .switch {
    margin:16px 0 0 16px;
}

.condition-item .small-input-field {
    margin:16px 0 0 16px;
    min-width:64px;
}

.condition-item .fa-trash-alt {
    float:right;
    margin:16px 16px 0 0;
}