.transaction-dialog {
}

.transaction-dialog .surrounder {
    padding:16px;
    width:512px;
    display:block;
    text-align:left;
}

.transaction-dialog .dialog-title {
    width:100%;
}

.transaction-dialog p {
    margin:4px 0;
}

.transaction-dialog .switch {
    position:absolute;
    right:16px;
    top:20px;
}

.transaction-dialog .save-button {
    width:160px;
    position:absolute;
    right:16px;
    bottom:16px;
}

.transaction-dialog .count-input {
    width:48px;
    background:#4D79FF;
    color:white;
    border:none;
    border-radius:3px;
    padding:0 2px;
}