.position-overview-item {
    width:98%;
    height:48px;
    margin:8px auto;
    text-align:left;
    overflow:hidden;
}

.position-overview-item .status {
    height:100%;
    width:4px;
    float:left;
    border-top-left-radius:4px;
    border-bottom-left-radius:4px;
}

.position-overview-item .info-text {
    padding:8px 0 0 8px;
    float:left;
    text-overflow:ellipsis;
    overflow:hidden;
    max-height:32px;
}

.position-overview-item .information-holder {
    width:100%;
    margin:8px;
}

.position-overview-item .information-holder li {
    margin-right:8px;
    width:128px;
    display:inline-block;
    float:left;
}

.position-overview-item .position-dialog-icon {
    margin:8px 8px 0 0;
    float:right;
    cursor: pointer;
}