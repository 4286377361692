.order-dialog {
}

.order-dialog .order-dialog-surrounder {
    padding:8px;
    width:1024px;
    display:block;
    text-align:left;
    overflow:hidden;
}

.order-dialog .dialog-title {
    width:100%;
}

.order-dialog .field {
    width:264px;
    margin:16px 8px 0 0;
}

.order-dialog .checkbox-field {
    padding-top:0;
}

.order-dialog .checkbox {
    padding-right:4px;
}

.order-dialog .textarea {
    width:100%;
    margin:8px 0 8px 0;
}

.order-dialog .element-group {
    display:flex;
    position:relative;
}

.order-dialog .part-field {
    width:80%;
}

.order-dialog .part-icon {
    width:24px;
    height:24px;
    margin:16px 0 0 16px;
}

.order-dialog .close-icon {
    float:right;
    margin:8px 8px 0 0;
}

.order-dialog .no-customer-found-text {
    color:red;
    font-size:10px;
}

.order-dialog .add-attribute-button {
    width:256px;
    margin: 8px auto;
}

.order-dialog .button {
    float:right;
    margin:8px 0;
}

.order-dialog .icon {
    margin-left:8px;
}

.order-dialog .inactive {
    color:gray;
    cursor:not-allowed;
}

.order-dialog .inactive-info {
    color:gray;
}

.order-dialog .button-holder {
    width:100%;
    float:right;
    display:flex;
}

.order-dialog .add-file-item {
    width:100%;
    height:32px;
    text-align: center;
    margin:4px auto;
    cursor: pointer;
}

.order-dialog .add-file-icon {
    margin:4px auto;
}

.order-dialog .add-file-icon:hover {
    opacity: 1;
}

.order-dialog .document-category {
    margin:4px auto;
    padding:4px;
    cursor:pointer;
}

.order-dialog .file-item {
    margin:4px auto;
}

.order-dialog .header-content {
    height:24px;
    padding:4px 0 0 4px;
}

.order-dialog .toggle-icon {
    float:right;
}

.order-dialog .status-field {
    float: right;
    margin:0 8px 0 0;
}

.order-dialog .position-display-switch {
    margin:0 0 0 8px;
}

.order-dialog .position-display-holder {
    text-align:center;
    margin:0;
    padding:0;
    width:100%;
}

.order-dialog .left-holder {
    width:3.5%;
    display:flex;
    cursor:pointer;
}

.order-dialog .left-holder-start {
    width:3.5%;
    display:flex;
    cursor:pointer;
}

.order-dialog .position-navigation-holder {
    display:inline-block;
}

.order-dialog .navigation-item {
    display: inline-block;
}

.order-dialog .position-navigation-icon {
    margin:auto;
    width:48px;
    height:48px;
    color:#4D79FF;
    cursor:pointer;
}

.order-dialog .position-navigation-icon:hover {
    color:#2B4591;
}

.order-dialog .position-info {
    margin:8px;
}

.order-dialog .quick-actions {
    position:fixed;
    top:32px;
    right:32px;
    width:256px;
    padding:8px;
    z-index:1000;
}

@media screen and (min-width: 1600px) {
    .order-dialog .order-dialog-surrounder {width:1500px;}
    .order-dialog .meta-information-holder {padding:8px;width:48%;float:left;}
    .order-dialog .position-content-holder {padding:8px;width:48%;float:right;height:720px;}
    .order-dialog .quick-actions {display:none;}
    .order-dialog .position-holder {height:600px;}
    .order-dialog .positions {overflow:auto;height:100%;}
    .order-dialog .button-holder {position:absolute;margin-top:64px;width:720px;text-align:right;}
}