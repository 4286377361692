.file {
    width:100%;
    height:64px;
    margin:8px auto;
    text-align: center;
}

.file .file-symbol {
    width:48px;
    height:48px;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
    margin:8px auto;
    cursor:pointer;
}

.file .file-symbol:hover {
    opacity: 0.8;
}

.file .pdf-icon {
    font-size: 24px;
    margin-top:12px;
}

.file .pdf-icon:hover {
    opacity: 1;
}

.file .file-name {
    font-size:10px;
    width:100%;
    text-align: center;
}