.UserMenu {
    position:absolute;
    top:0;
    right:0;
    left:0;
    bottom:85%;
}

.UserMenu h2 {
    position:absolute;
    right:48px;
    bottom:48px;
    cursor: pointer;
}

.UserMenu .MenuPanel {
    position: absolute;
    right:32px;
    top:70%;
    background-color: white;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
    z-index: 30;
}

.UserMenu .active-display {
    position: absolute;
    left:1%;
    top:48px;
}

.UserMenu ul li {
    cursor: pointer;
    width:256px;
    margin:8px 0;
}

@media screen and (max-width: 1023px) {
    .UserMenu {
        bottom:75%;
    }
}