.user-administration {
    width:100%;
    height:100%;
    text-align:left;
}

.user-administration h1 {
    margin:32px 0 0 32px;
}

.user-administration .icon {
    float:right;
    cursor: pointer;
    margin:32px 32px 0 0;
    color:black;
}

.user-administration .item-list {
    position: absolute;
    top:96px;
    bottom:0;
    right:0;
    left:0;
    overflow:auto;
}

.user-administration .add-fab {
    position:absolute;
    bottom:32px;
    right:32px;
}