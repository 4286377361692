.import-data-dialog {

}

.import-data-dialog .surrounder {
    width:512px;
    height:128px;
    padding-top:32px;
}

.import-data-dialog h3 {
    text-align: center;
}

.import-data-dialog .import-info {
    margin-top:16px;
    color:gray;
}