.automated-communication-settings-dialog {

}

.automated-communication-settings-dialog .surrounder {
    padding:8px;
    width:720px;
    display:block;
    text-align:left;
}

.automated-communication-settings-dialog .dialog-title {
    width:100%;
}

.automated-communication-settings-dialog .field {
    width:128px;
    margin:16px 8px 0 0;
}

.automated-communication-settings-dialog .small-field {
    width:64px;
}

.automated-communication-settings-dialog .textarea {
    width:100%;
}

.automated-communication-settings-dialog .save-button {
    float:right;
    margin:0 16px 16px 0;
}

.automated-communication-settings-dialog .explanation-text {
    color:gray;
    margin:8px 0 0 0;
    font-size:12px;
}

.automated-communication-settings-dialog .automated-communication-setting {
    width:100%;
    height:40px;
    margin-top:8px;
    cursor:pointer;
    position: relative;
}

.automated-communication-settings-dialog .automated-communication-setting p {
    float:left;
    margin:12px 0 0 8px;
}

.automated-communication-settings-dialog .automated-communication-setting .active-label {
    position:absolute;
    right:8px;
}